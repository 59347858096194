import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../assets/styles/variables';

const FormInpuEl = styled.input`
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${COLORS.border};
  box-shadow: none;
  -webkit-appearance: none;
  outline: 0;
  width: 100%;
`;

const FormInput = ({ type, name, placeholder, required, ...rest }) => (
  <FormInpuEl name={name} type={type} placeholder={`${placeholder} ${required ? '*' : ''}`} {...rest} />
);

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

FormInput.defaultProps = {
  type: 'text',
};

export default FormInput;
